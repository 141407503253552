import { RouteConfig, MetaData } from 'vue-router'

export const others: RouteConfig[] = [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('./acl/AccessControl.vue'),
    meta: <MetaData>{
      resource: 'ACL',
      action: 'read',
    },
  },
]

export const _ = null
