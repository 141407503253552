import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '../route-view'
import { pageLayouts } from './page-layouts'

export const uis: RouteConfig[] = [
  {
    path: '/ui',
    name: 'ui',
    redirect: '/ui/typography',
    component: createEmptyView(),
    children: [
      {
        path: 'typography',
        name: 'ui-typography',
        component: () => import('./typography/Typography.vue'),
        meta: <MetaData>{
          pageTitle: 'Typography',
          breadcrumb: [
            {
              text: 'UI',
            },
            {
              text: 'Typography',
              active: true,
            },
          ],
        },
      },
      {
        path: 'colors',
        name: 'ui-colors',
        component: () => import('./colors/Colors.vue'),
        meta: <MetaData>{
          pageTitle: 'Colors',
          breadcrumb: [
            {
              text: 'UI',
            },
            {
              text: 'Colors',
              active: true,
            },
          ],
        },
      },
      {
        path: 'feather',
        name: 'ui-feather',
        component: () => import('./feather/Feather.vue'),
        meta: <MetaData>{
          pageTitle: 'Feather',
          breadcrumb: [
            {
              text: 'UI',
            },
            {
              text: 'Feather',
              active: true,
            },
          ],
        },
      },
    ],
  },
  ...pageLayouts,
]

export const _ = null
