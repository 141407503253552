// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'

import { $themeConfig } from '@/themeConfig'

export const VERTICAL_MENU_MODULE_NAME = 'verticalMenu'

export interface VerticalMenuState {
  isVerticalMenuCollapsed: boolean
}

@Module({
  name: VERTICAL_MENU_MODULE_NAME,
  namespaced: true,
})
export class VerticalMenuModule extends VuexModule implements VerticalMenuState {
  isVerticalMenuCollapsed = $themeConfig.layout.menu.isCollapsed

  @Mutation
  updateVerticalMenuCollapsed(val: boolean) {
    this.isVerticalMenuCollapsed = val
  }
}
