import { RouteConfig, MetaData } from 'vue-router'

export const errors: RouteConfig[] = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('./Error404.vue'),
    meta: <MetaData>{
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]

export const _ = null
