import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '@/views/route-view'

export const formElements: RouteConfig[] = [
  {
    path: 'form-element',
    name: 'form-element',
    redirect: 'form-element/form-select',
    component: createEmptyView(),
    children: [
      {
        path: 'form-select',
        name: 'forms-element-select',
        component: () => import('./form-select/FormSelect.vue'),
        meta: <MetaData>{
          pageTitle: 'Select',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Select',
              active: true,
            },
          ],
        },
      },
      {
        path: 'switch',
        name: 'forms-element-switch',
        component: () => import('./form-switch/Switch.vue'),
        meta: <MetaData>{
          pageTitle: 'Switch',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Switch',
              active: true,
            },
          ],
        },
      },
      {
        path: 'checkbox',
        name: 'forms-element-checkbox',
        component: () => import('./form-checkbox/FormCheckbox.vue'),
        meta: <MetaData>{
          pageTitle: 'Checkbox',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Checkbox',
              active: true,
            },
          ],
        },
      },
      {
        path: 'radio',
        name: 'forms-element-radio',
        component: () => import('./form-radio/FormRadio.vue'),
        meta: <MetaData>{
          pageTitle: 'Radio',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Radio',
              active: true,
            },
          ],
        },
      },
      {
        path: 'input',
        name: 'forms-element-input',
        component: () => import('./form-input/FormInput.vue'),
        meta: <MetaData>{
          pageTitle: 'Input',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Input',
              active: true,
            },
          ],
        },
      },
      {
        path: 'textarea',
        name: 'forms-element-textarea',
        component: () => import('./form-textarea/FormTextArea.vue'),
        meta: {
          pageTitle: 'Textarea',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Textarea',
              active: true,
            },
          ],
        },
      },
      {
        path: 'spinbutton',
        name: 'forms-element-spinbutton',
        component: () => import('./form-spinbutton/FormSpinbutton.vue'),
        meta: <MetaData>{
          pageTitle: 'Spinbutton',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Spinbutton',
              active: true,
            },
          ],
        },
      },
      {
        path: 'input-group',
        name: 'forms-element-input-group',
        component: () => import('./form-input-group/FormInputGroup.vue'),
        meta: <MetaData>{
          pageTitle: 'Input Group',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Input Group',
              active: true,
            },
          ],
        },
      },
      {
        path: 'rating',
        name: 'forms-element-rating',
        component: () => import('./form-rating/FormRating.vue'),
        meta: {
          pageTitle: 'Form Rating',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Form Rating',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tag',
        name: 'forms-element-tag',
        component: () => import('./form-tag/FormTag.vue'),
        meta: {
          pageTitle: 'Form Tag',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Form Tag',
              active: true,
            },
          ],
        },
      },
      {
        path: 'datepicker',
        name: 'forms-element-datepicker',
        component: () => import('./form-datepicker/FormDatepicker.vue'),
        meta: {
          pageTitle: 'Form Datepicker',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Form Datepicker',
              active: true,
            },
          ],
        },
      },
      {
        path: 'timepicker',
        name: 'forms-element-timepicker',
        component: () => import('./form-timepicker/FormTimepicker.vue'),
        meta: {
          pageTitle: 'Form Timepicker',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Form Timepicker',
              active: true,
            },
          ],
        },
      },
      {
        path: 'file-input',
        name: 'forms-element-file-input',
        component: () => import('./form-file-input/FileInput.vue'),
        meta: <MetaData>{
          pageTitle: 'File Input',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'File Input',
              active: true,
            },
          ],
        },
      },
      {
        path: 'auto-suggest',
        name: 'extensions-auto-suggest',
        component: () => import('./autosuggest/AutoSuggest.vue'),
        meta: {
          pageTitle: 'Auto Suggest',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Auto Suggest',
              active: true,
            },
          ],
        },
      },
      {
        path: 'vue-select',
        name: 'extensions-vue-select',
        component: () => import('./vue-select/VueSelect.vue'),
        meta: <MetaData>{
          pageTitle: 'Vue Select',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Vue Select',
              active: true,
            },
          ],
        },
      },
      {
        path: 'quill-editor',
        name: 'extensions-quill-editor',
        component: () => import('./quill-editor/QuillEditor.vue'),
        meta: {
          pageTitle: 'Quill Editor',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Quill Editor',
              active: true,
            },
          ],
        },
      },

      {
        path: 'date-time-picker',
        name: 'extensions-date-time-picker',
        component: () => import('./date-time-picker/DateTimePicker.vue'),
        meta: <MetaData>{
          pageTitle: 'Date Time Picker',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Date Time Picker',
              active: true,
            },
          ],
        },
      },
      {
        path: 'form-input-mask',
        name: 'forms-element-input-mask',
        component: () => import('./form-input-mask/FormInputMask.vue'),
        meta: <MetaData>{
          pageTitle: 'Input Mask',
          breadcrumb: [
            {
              text: 'Form Elements',
            },
            {
              text: 'Input Mask',
              active: true,
            },
          ],
        },
      },
    ],
  },
]

export const _ = null
