/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import { $themeBreakpoints } from '@/themeConfig'

export const APP_MODULE_NAME = 'app'

export interface AppState {
  windowWidth: number;
  shallShowOverlay: boolean;
}

@Module({
  name: APP_MODULE_NAME,
  namespaced: true,
})
export class AppModule extends VuexModule implements AppState {
  private _windowWidth = 0

  private _shallShowOverlay = false

  get windowWidth() {
    return this._windowWidth
  }

  get shallShowOverlay() {
    return this._shallShowOverlay
  }

  get currentBreakPoint() {
    const { windowWidth } = this
    if (windowWidth >= $themeBreakpoints.xl) {
      return 'xl'
    }

    if (windowWidth >= $themeBreakpoints.lg) {
      return 'lg'
    }

    if (windowWidth >= $themeBreakpoints.md) {
      return 'md'
    }

    if (windowWidth >= $themeBreakpoints.sm) {
      return 'sm'
    }

    return 'xs'
  }

  @Mutation
  UPDATE_WINDOW_WIDTH(value: number) {
    this._windowWidth = value
  }

  @Mutation
  TOGGLE_OVERLAY(value: boolean | undefined) {
    if (value !== undefined) {
      this._shallShowOverlay = value
    }
  }
}
