import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '@/views/route-view'

import { formElements } from './form-element'

export const forms: RouteConfig[] = [
  {
    path: '/form',
    name: 'form',
    redirect: '/form/form-element',
    component: createEmptyView(),
    children: [
      ...formElements,
      {
        path: 'form-layout',
        name: 'form-layout',
        component: () => import('./form-layout/FormLayout.vue'),
        meta: <MetaData>{
          pageTitle: 'Form Layout',
          breadcrumb: [
            {
              text: 'Forms',
            },
            {
              text: 'Form Layout',
              active: true,
            },
          ],
        },
      },
      {
        path: 'form-wizard',
        name: 'form-wizard',
        component: () => import('./form-wizard/FormWizard.vue'),
        meta: {
          pageTitle: 'Form Wizard',
          breadcrumb: [
            {
              text: 'Forms',
            },
            {
              text: 'Form Wizard',
              active: true,
            },
          ],
        },
      },
      {
        path: 'form-validation',
        name: 'form-validation',
        component: () => import('./form-validation/FormValidation.vue'),
        meta: <MetaData>{
          pageTitle: 'Form Validation',
          breadcrumb: [
            {
              text: 'Forms',
            },
            {
              text: 'Form Validation',
              active: true,
            },
          ],
        },
      },
      {
        path: 'form-repeater',
        name: 'form-repeater',
        component: () => import('./form-repeater/FormRepeater.vue'),
        meta: <MetaData>{
          pageTitle: 'Form Repeater',
          breadcrumb: [
            {
              text: 'Forms',
            },
            {
              text: 'Form Repeater',
              active: true,
            },
          ],
        },
      },
    ],
  },
]

export const _ = null
