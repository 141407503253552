import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '@/views/route-view'

export const chart: RouteConfig[] = [
  {
    path: '/charts',
    name: 'charts',
    redirect: '/charts/apex-chart',
    component: createEmptyView(),
    children: [
      {
        path: 'apex-chart',
        name: 'charts-apex-chart',
        component: () => import('./apex-chart/ApexChart.vue'),
        meta: <MetaData>{
          pageTitle: 'Apex Chart',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Apex Chart',
              active: true,
            },
          ],
        },
      },
      {
        path: 'chartjs',
        name: 'charts-chartjs',
        component: () => import('./chartjs/Chartjs.vue'),
        meta: <MetaData>{
          pageTitle: 'Chartjs',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Chartjs',
              active: true,
            },
          ],
        },
      },
      {
        path: 'echart',
        name: 'charts-echart',
        component: () => import('./echart/Echart.vue'),
        meta: <MetaData>{
          pageTitle: 'Echart',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Echart',
              active: true,
            },
          ],
        },
      },
    ],
  },
]

export const _ = null
