













import Vue from 'vue'

// This will be populated in `beforeCreate` hook
import { provideToast } from 'vue-toastification/composition'
import { watch, computed } from '@vue/composition-api'
import { useWindowSize, useCssVar } from '@vueuse/core'
import { userAppConfig } from '@/vuexy/app-config'

import {
  colors,
  breakpoints,
  $themeColors,
  $themeBreakpoints,
  $themeConfig,
} from '@/themeConfig'

import { useRouter } from '@/vuexy/utils/utils'

import { getAppStore, getAppConfigStore } from '@/store'

const LayoutFull = () => import('@/vuexy/layouts/layout-full/LayoutFull.vue')
const LayoutVertical = () => import('@/vuexy/layouts/layout-vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/vuexy/layouts/layout-horizontal/LayoutHorizontal.vue')
const AppCustomizer = () => import('@/vuexy/layouts/components/app-customizer/AppCustomizer.vue')

const appModule = getAppStore()
const appConfigModule = getAppConfigStore()

export default Vue.extend({
  components: {
    // Layouts
    LayoutFull,
    AppCustomizer,
    LayoutVertical,
    LayoutHorizontal,
  },
  beforeCreate() {
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim()
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { route } = useRouter()
    const { skin, skinClasses } = userAppConfig()
    const showCustomizer = $themeConfig.layout.customizer

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') {
      document.body.classList.add('dark-layout')
    }

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    appModule.UPDATE_WINDOW_WIDTH(window.innerWidth)

    const { width: windowWidth } = useWindowSize()

    watch(() => windowWidth.value, appModule.UPDATE_WINDOW_WIDTH)

    const contentLayoutType = computed(() => appConfigModule.layout.type)

    const layout = computed(() => {
      const { meta } = route.value

      if (meta && meta.layout === 'full') {
        return 'layout-full'
      }

      return `layout-${contentLayoutType.value}`
    })

    return {
      layout,
      skinClasses,
      showCustomizer,
      contentLayoutType,
    }
  },
})
