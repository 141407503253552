import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '@/views/route-view'

export const pageLayouts: RouteConfig[] = [
  {
    path: '/page-layouts',
    name: 'page-layout',
    redirect: '/page-layouts/collapsed-menu',
    component: createEmptyView(),
    children: [
      {
        path: 'collapsed-menu',
        name: 'page-layout-collapsed-menu',
        component: () => import('@/views/ui/page-layouts/CollapsedMenu.vue'),
        meta: <MetaData>{
          pageTitle: 'Layout Collapsed Menu',
          breadcrumb: [
            {
              text: 'Layouts',
            },
            {
              text: 'Collapsed Menu',
              active: true,
            },
          ],
        },
      },
      {
        path: 'boxed-layout',
        name: 'page-layout-boxed-layout',
        component: () => import('@/views/ui/page-layouts/BoxedLayout.vue'),
        meta: <MetaData>{
          pageTitle: 'Boxed Layout',
          breadcrumb: [
            {
              text: 'Layouts',
            },
            {
              text: 'Boxed Layout',
              active: true,
            },
          ],
        },
      },
      {
        path: 'without-menu',
        name: 'page-layout-without-menu',
        component: () => import('@/views/ui/page-layouts/WithoutMenu.vue'),
        meta: <MetaData>{
          pageTitle: 'Layout Without Menu',
          breadcrumb: [
            {
              text: 'Layouts',
            },
            {
              text: 'Layout Without Menu',
              active: true,
            },
          ],
        },
      },
      {
        path: 'layout-empty',
        name: 'page-layout-layout-empty',
        component: () => import('@/views/ui/page-layouts/LayoutEmpty.vue'),
        meta: <MetaData>{
          pageTitle: 'Layout Empty',
          breadcrumb: [
            {
              text: 'Layouts',
            },
            {
              text: 'Layout Empty',
              active: true,
            },
          ],
        },
      },
      {
        path: 'layout-blank',
        name: 'page-layout-layout-blank',
        component: () => import('@/views/ui/page-layouts/LayoutBlank.vue'),
        meta: <MetaData>{
          layout: 'full',
          pageTitle: 'Layout Blank',
        },
      },
    ],
  },
]

export const _ = null
