/* eslint-disable class-methods-use-this */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'

import axios from '@/libs/axios'

export const ECOMMERCE_MODULE_NAME = 'app-ecommerce'

export interface ECommerceState {
  cartItemsCount: number
}

@Module({
  name: ECOMMERCE_MODULE_NAME,
  namespaced: true,
})
export class ECommerceModule extends VuexModule implements ECommerceState {
  cartItemsCount = (() => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')

    return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
  })()

  @Mutation
  UPDATE_CART_ITEMS_COUNT(count: number) {
    this.cartItemsCount = count
  }

  @Action
  fetchProducts(...payload: any[]) {
    return new Promise((resolve, reject) => {
      axios
        .get('/apps/ecommerce/products', { params: payload })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  fetchProduct({ productId }: any) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/apps/ecommerce/products/${productId}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  fetchWishlistProducts() {
    return new Promise((resolve, reject) => {
      axios
        .get('/apps/ecommerce/wishlist')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  fetchCartProducts() {
    return new Promise((resolve, reject) => {
      axios
        .get('/apps/ecommerce/cart')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // ------------------------------------------------
  // Product Actions
  // ------------------------------------------------
  @Action
  addProductInWishlist({ productId }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post('/apps/ecommerce/wishlist', { productId })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  removeProductFromWishlist({ productId }: any) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/apps/ecommerce/wishlist/${productId}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  addProductInCart({ productId }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post('/apps/ecommerce/cart', { productId })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  removeProductFromCart({ productId }: any) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/apps/ecommerce/cart/${productId}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}
