import { RouteConfig, MetaData } from 'vue-router'

export const maps: RouteConfig[] = [
  {
    path: '/maps/leaflet',
    name: 'maps-leaflet',
    component: () => import('./leaflet/Leaflet.vue'),
    meta: <MetaData>{
      pageTitle: 'Leaflet',
      breadcrumb: [
        {
          text: 'Table',
        },
        {
          text: 'Leaflet',
          active: true,
        },
      ],
    },
  },
]

export const _ = null
