import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '../route-view'

export const cards: RouteConfig[] = [
  {
    path: '/card',
    name: 'card',
    redirect: '/card/card-basic',
    component: createEmptyView(),
    children: [
      {
        path: 'card-basic',
        name: 'card-basic',
        component: () => import('./card-basic/CardBasic.vue'),
        meta: <MetaData>{
          pageTitle: 'Basic Card',
          breadcrumb: [
            {
              text: 'Card',
            },
            {
              text: 'Basic Card',
              active: true,
            },
          ],
        },
      },
      {
        path: 'card-advance',
        name: 'card-advance',
        component: () => import('./card-advance/CardAdvance.vue'),
        meta: <MetaData>{
          pageTitle: 'Advance Card',
          breadcrumb: [
            {
              text: 'Card',
            },
            {
              text: 'Advance Card',
              active: true,
            },
          ],
        },
      },
      {
        path: 'statistic',
        name: 'card-statistic',
        component: () => import('./card-statistic/CardStatistic.vue'),
        meta: <MetaData>{
          pageTitle: 'Statistics Cards',
          breadcrumb: [
            {
              text: 'Card',
            },
            {
              text: 'Statistics Cards',
              active: true,
            },
          ],
        },
      },
      {
        path: 'analytic',
        name: 'card-analytic',
        component: () => import('./card-analytic/CardAnalytic.vue'),
        meta: <MetaData>{
          pageTitle: 'Analytics Cards',
          breadcrumb: [
            {
              text: 'Card',
            },
            {
              text: 'Analytics Cards',
              active: true,
            },
          ],
        },
      },
      {
        path: 'action',
        name: 'card-action',
        component: () => import('./card-action/CardAction.vue'),
        meta: <MetaData>{
          pageTitle: 'Card Actions',
          breadcrumb: [
            {
              text: 'Card',
            },
            {
              text: 'Card Actions',
              active: true,
            },
          ],
        },
      },
    ],
  },
]

export const _ = null
