import Vue, { CreateElement } from 'vue'
import { VueSelect } from 'vue-select'

// Set the components prop default to return our fresh components

/** const vSelect = VueSelect.extend({
  props: {
    components: {
      default: () => ({
        Deselect: {
          render: (h: CreateElement) => h('feather-icon', { props: { size: '14', icon: 'XIcon' } }),
        },
        OpenIndicator: {
          render: (h: CreateElement) => h('feather-icon', { props: { size: '15', icon: 'ChevronDownIcon' }, class: 'open-indicator' }),
        },
      }),
    },
  },
}) */

Vue.component('vSelect', VueSelect)
