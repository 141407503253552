import { RouteConfig } from 'vue-router'
import { chart } from './charts'
import { maps } from './maps'

export const chartsAndMaps: RouteConfig[] = [
  ...chart,
  ...maps,
]

export const _ = null
