import { computed, watch } from '@vue/composition-api'

import { useRouter } from '@/vuexy/utils/utils'
import { getAppStore, getAppConfigStore, getVerticalMenuStore } from '@/store'
import { $themeBreakpoints } from '@/themeConfig'

export const userAppConfig = () => {
  const { route } = useRouter()

  const appStore = getAppStore()
  const appConfigStore = getAppConfigStore()
  const verticalMenuStore = getVerticalMenuStore()

  // get windowWidth
  const windowWidth = computed(() => appStore.windowWidth)

  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => verticalMenuStore.isVerticalMenuCollapsed,
    set: val => {
      verticalMenuStore.updateVerticalMenuCollapsed(val)
    },
  })

  // auto hide vertical menu when change route
  watch(() => route.value, () => {
    if (windowWidth.value < $themeBreakpoints.xl) {
      isVerticalMenuCollapsed.value = false
    }
  })

  // ------------------------------------------------
  // RTL
  // ------------------------------------------------
  const isRTL = computed({
    get: () => appConfigStore.layout.isRTL,
    set: _ => {
      appConfigStore.TOGGLE_RTL()
    },
  })

  // ------------------------------------------------
  // Skin
  // ------------------------------------------------
  const skin = computed({
    get: () => appConfigStore.layout.skin,
    set: (val: any) => {
      appConfigStore.UPDATE_SKIN(val)
    },
  })

  const skinClasses = computed(() => {
    if (skin.value === 'bordered') {
      return 'bordered-layout'
    }

    if (skin.value === 'semi-dark') {
      return 'semi-dark-layout'
    }

    // Do not return any class for dark layout because dark layout updates class in body
    // Do not return any class for light layout as that is default layout
    return null
  })

  // ------------------------------------------------
  // routerTransition
  // ------------------------------------------------
  const routerTransition = computed({
    get: () => appConfigStore.layout.routerTransition,
    set: (val: any) => {
      appConfigStore.UPDATE_ROUTER_TRANSITION(val)
    },
  })

  // *===============================================---*
  // *--------- LAYOUT ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // layoutType
  // ------------------------------------------------

  const layoutType = computed({
    get: () => appConfigStore.layout.type,
    set: (val: any) => {
      appConfigStore.UPDATE_LAYOUT_TYPE(val)
    },
  })

  // Reset skin if skin is semi-dark and move to horizontal layout
  watch(layoutType, val => {
    if (val === 'horizontal' && skin.value === 'semi-dark') {
      skin.value = 'light'
    }
  })

  // ------------------------------------------------
  // Content Width (Full/Boxed)
  // ------------------------------------------------
  const contentWidth = computed({
    get: () => appConfigStore.layout.contentWidth,
    set: (val: any) => {
      appConfigStore.UPDATE_CONTENT_WIDTH(val)
    },
  })

  const containerType = computed(() => (contentWidth.value === 'boxed' ? 'container p-0' : null))

  // ------------------------------------------------
  // isNavMenuHidden
  // ------------------------------------------------
  const isNavMenuHidden = computed({
    get: () => appConfigStore.layout.menu.hidden,
    set: val => {
      appConfigStore.UPDATE_NAV_MENU_HIDDEN(val)
    },
  })

  // *===============================================---*
  // *--------- NAVBAR ---------------------------------------*
  // *===============================================---*

  const navbarBackgroundColor = computed({
    get: () => appConfigStore.layout.navbar.backgroundColor,
    set: val => {
      appConfigStore.UPDATE_NAVBAR_CONFIG({ backgroundColor: val })
    },
  })

  const navbarType = computed({
    get: () => appConfigStore.layout.navbar.type,
    set: val => {
      appConfigStore.UPDATE_NAVBAR_CONFIG({ type: val })
    },
  })

  // *===============================================---*
  // *--------- FOOTER ---------------------------------------*
  // *===============================================---*

  const footerType = computed({
    get: () => appConfigStore.layout.footer.type,
    set: val => {
      appConfigStore.UPDATE_FOOTER_CONFIG({ type: val })
    },
  })

  // Overlay configs
  const shallShowOverlay = computed(() => appStore.shallShowOverlay)

  const contentClass = computed<string>(() => {
    const { meta } = route.value

    if (meta) {
      return meta.contentClass
    }

    return ''
  })

  return {
    isVerticalMenuCollapsed,
    isRTL,
    skin,
    skinClasses,
    route,
    routerTransition,

    // Navbar
    navbarBackgroundColor,
    navbarType,

    // Footer
    footerType,

    // Layout
    layoutType,
    contentWidth,
    containerType,
    isNavMenuHidden,

    windowWidth,

    // overlay
    contentClass,
    shallShowOverlay,
  }
}

export const _ = null
