import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '../route-view'

export const tables: RouteConfig[] = [
  {
    path: '/table',
    name: 'table',
    redirect: '/table/bs-table',
    component: createEmptyView(),
    children: [
      {
        path: 'bs-table',
        name: 'table-bs-table',
        component: () => import('./bs-table/Table.vue'),
        meta: <MetaData>{
          pageTitle: 'BS Table',
          breadcrumb: [
            {
              text: 'Table',
            },
            {
              text: 'BS Table',
              active: true,
            },
          ],
        },
      },
      {
        path: 'vue-good-table',
        name: 'table-good-table',
        component: () => import('./vue-good-table/GoodTable.vue'),
        meta: <MetaData>{
          pageTitle: 'Good Table',
          breadcrumb: [
            {
              text: 'Table',
            },
            {
              text: 'Good Table',
              active: true,
            },
          ],
        },
      },

    ],
  },
]

export const _ = null
