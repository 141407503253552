/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import { $themeConfig } from '@/themeConfig'

export const APP_CONFIG_MODULE_NAME = 'appConfig'

export interface AppConfigState {
  layout: {
    isRTL: boolean;
    skin: string;
    routerTransition: string;
    type: string;
    contentWidth: string;
    menu: {
      hidden: boolean;
    };
    navbar: {
      type: string;
      backgroundColor: string;
    };
    footer: {
      type: string;
    };
  }
}

@Module({
  name: APP_CONFIG_MODULE_NAME,
  namespaced: true,
})
export class AppConfigModule extends VuexModule implements AppConfigState {
  layout = {
    isRTL: $themeConfig.layout.isRTL,
    skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
    routerTransition: $themeConfig.layout.routerTransition,
    type: $themeConfig.layout.type,
    contentWidth: $themeConfig.layout.contentWidth,
    menu: {
      hidden: $themeConfig.layout.menu.hidden,
    },
    navbar: {
      type: $themeConfig.layout.navbar.type,
      backgroundColor: $themeConfig.layout.navbar.backgroundColor,
    },
    footer: {
      type: $themeConfig.layout.footer.type,
    },
  }

  @Mutation
  TOGGLE_RTL() {
    const { layout } = this

    layout.isRTL = !layout.isRTL
    document.documentElement.setAttribute('dir', layout.isRTL ? 'rtl' : 'ltr')
  }

  @Mutation
  UPDATE_SKIN(skin: SKIN_TYPE) {
    const { layout } = this

    layout.skin = skin

    // Update value in localStorage
    localStorage.setItem('vuexy-skin', skin)

    // Update DOM for dark-layout
    if (skin === 'dark') {
      document.body.classList.add('dark-layout')
    } else if (document.body.className.match('dark-layout')) {
      document.body.classList.remove('dark-layout')
    }
  }

  @Mutation
  UPDATE_ROUTER_TRANSITION(routerTransition: ROUTER_TRANS_TYPE) {
    const { layout } = this

    layout.routerTransition = routerTransition
  }

  @Mutation
  UPDATE_LAYOUT_TYPE(type: LAYOUT_TYPE) {
    const { layout } = this

    layout.type = type
  }

  @Mutation
  UPDATE_CONTENT_WIDTH(contentWidth: CONTENT_WIDTH_TYPE) {
    const { layout } = this

    layout.contentWidth = contentWidth
  }

  @Mutation
  UPDATE_NAV_MENU_HIDDEN(val: any) {
    const { layout } = this

    layout.menu.hidden = val
  }

  @Mutation
  UPDATE_NAVBAR_CONFIG(obj: any) {
    const { layout } = this

    Object.assign(layout.navbar, obj)
  }

  @Mutation
  UPDATE_FOOTER_CONFIG(obj: any) {
    const { layout } = this

    Object.assign(layout.footer, obj)
  }
}
