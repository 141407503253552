import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '@/views/route-view'

export const components: RouteConfig[] = [
  {
    path: '/components',
    name: 'components',
    redirect: '/components/alert',
    component: createEmptyView(),
    children: [
      {
        path: 'alert',
        name: 'components-alert',
        component: () => import('@/views/components/alert/Alert.vue'),
        meta: <MetaData>{
          pageTitle: 'Alerts',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Alerts',
              active: true,
            },
          ],
        },
      },
      {
        path: 'aspect',
        name: 'components-aspect',
        component: () => import('@/views/components/aspect/Aspect.vue'),
        meta: <MetaData>{
          pageTitle: 'Aspect',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Aspect',
              active: true,
            },
          ],
        },
      },
      {
        path: 'avatar',
        name: 'components-avatar',
        component: () => import('@/views/components/avatar/Avatar.vue'),
        meta: <MetaData>{
          pageTitle: 'Avatar',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Avatar',
              active: true,
            },
          ],
        },
      },
      {
        path: 'badge',
        name: 'components-badge',
        component: () => import('@/views/components/badge/Badge.vue'),
        meta: <MetaData>{
          pageTitle: 'Badge',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Badge',
              active: true,
            },
          ],
        },
      },
      {
        path: 'breadcrumb',
        name: 'components-breadcrumb',
        component: () => import('@/views/components/breadcrumb/Breadcrumb.vue'),
        meta: <MetaData>{
          pageTitle: 'Breadcrumb',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Breadcrumb',
              active: true,
            },
          ],
        },
      },
      {
        path: 'button',
        name: 'components-button',
        component: () => import('@/views/components/button/Button.vue'),
        meta: <MetaData>{
          pageTitle: 'Button',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Button',
              active: true,
            },
          ],
        },
      },
      {
        path: 'button-group',
        name: 'components-button-group',
        component: () => import('@/views/components/button-group/ButtonGroup.vue'),
        meta: <MetaData>{
          pageTitle: 'Button Group',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Button Group',
              active: true,
            },
          ],
        },
      },
      {
        path: 'button-toolbar',
        name: 'components-button-toolbar',
        component: () => import('@/views/components/button-toolbar/ButtonToolbar.vue'),
        meta: <MetaData>{
          pageTitle: 'Button Toolbar',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Button Toolbar',
              active: true,
            },
          ],
        },
      },
      {
        path: 'calendar',
        name: 'components-calendar',
        component: () => import('@/views/components/calendar/Calendar.vue'),
        meta: <MetaData>{
          pageTitle: 'Calendar',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Calendar',
              active: true,
            },
          ],
        },
      },
      {
        path: 'carousel',
        name: 'components-carousel',
        component: () => import('@/views/components/carousel/Carousel.vue'),
        meta: <MetaData>{
          pageTitle: 'Carousel',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Carousel',
              active: true,
            },
          ],
        },
      },
      {
        path: 'collapse',
        name: 'components-collapse',
        component: () => import('@/views/components/collapse/Collapse.vue'),
        meta: <MetaData>{
          pageTitle: 'Collapse',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Collapse',
              active: true,
            },
          ],
        },
      },
      {
        path: 'dropdown',
        name: 'components-dropdown',
        component: () => import('@/views/components/dropdown/Dropdown.vue'),
        meta: <MetaData>{
          pageTitle: 'Dropdown',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Dropdown',
              active: true,
            },
          ],
        },
      },
      {
        path: 'embed',
        name: 'components-embed',
        component: () => import('@/views/components/embed/Embed.vue'),
        meta: <MetaData>{
          pageTitle: 'Embed',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Embed',
              active: true,
            },
          ],
        },
      },
      {
        path: 'image',
        name: 'components-image',
        component: () => import('@/views/components/image/Image.vue'),
        meta: <MetaData>{
          pageTitle: 'Image',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Image',
              active: true,
            },
          ],
        },
      },
      {
        path: 'list-group',
        name: 'components-list-group',
        component: () => import('@/views/components/list-group/ListGroup.vue'),
        meta: <MetaData>{
          pageTitle: 'List Group',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'List Group',
              active: true,
            },
          ],
        },
      },
      {
        path: 'media',
        name: 'components-media',
        component: () => import('@/views/components/media/Media.vue'),
        meta: <MetaData>{
          pageTitle: 'Media Objects',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Media Objects',
              active: true,
            },
          ],
        },
      },
      {
        path: 'modal',
        name: 'components-modal',
        component: () => import('@/views/components/modal/Modal.vue'),
        meta: <MetaData>{
          pageTitle: 'Modal',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Modal',
              active: true,
            },
          ],
        },
      },
      {
        path: 'nav',
        name: 'components-nav',
        component: () => import('@/views/components/nav/Nav.vue'),
        meta: <MetaData>{
          pageTitle: 'Nav',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Nav',
              active: true,
            },
          ],
        },
      },
      {
        path: 'overlay',
        name: 'components-overlay',
        component: () => import('@/views/components/overlay/Overlay.vue'),
        meta: <MetaData>{
          pageTitle: 'Overlay',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Overlay',
              active: true,
            },
          ],
        },
      },
      {
        path: 'pagination',
        name: 'components-pagination',
        component: () => import('@/views/components/pagination/Pagination.vue'),
        meta: <MetaData>{
          pageTitle: 'Pagination',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Pagination',
              active: true,
            },
          ],
        },
      },
      {
        path: 'pagination-nav',
        name: 'components-pagination-nav',
        component: () => import('@/views/components/pagination-nav/PaginationNav.vue'),
        meta: <MetaData>{
          pageTitle: 'Pagination Nav',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Pagination Nav',
              active: true,
            },
          ],
        },
      },
      {
        path: 'pill',
        name: 'components-pill',
        component: () => import('@/views/components/pill/Pill.vue'),
        meta: <MetaData>{
          pageTitle: 'Pill',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Pill',
              active: true,
            },
          ],
        },
      },
      {
        path: 'pill-badge',
        name: 'components-pill-badge',
        component: () => import('@/views/components/pill-badge/PillBadge.vue'),
        meta: <MetaData>{
          pageTitle: 'Pill Badge',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Pill Badge',
              active: true,
            },
          ],
        },
      },
      {
        path: 'popover',
        name: 'components-popover',
        component: () => import('@/views/components/popover/Popover.vue'),
        meta: <MetaData>{
          pageTitle: 'Popover',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Popover',
              active: true,
            },
          ],
        },
      },
      {
        path: 'progress',
        name: 'components-progress',
        component: () => import('@/views/components/progress/Progress.vue'),
        meta: <MetaData>{
          pageTitle: 'Progress',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Progress',
              active: true,
            },
          ],
        },
      },
      {
        path: 'sidebar',
        name: 'components-sidebar',
        component: () => import('@/views/components/sidebar/Sidebar.vue'),
        meta: <MetaData>{
          pageTitle: 'Sidebar',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Sidebar',
              active: true,
            },
          ],
        },
      },
      {
        path: 'spinner',
        name: 'components-spinner',
        component: () => import('@/views/components/spinner/Spinner.vue'),
        meta: <MetaData>{
          pageTitle: 'Spinner',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Spinner',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tab',
        name: 'components-tab',
        component: () => import('@/views/components/tab/Tab.vue'),
        meta: <MetaData>{
          pageTitle: 'Tab',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Tab',
              active: true,
            },
          ],
        },
      },
      {
        path: 'time',
        name: 'components-time',
        component: () => import('@/views/components/time/Time.vue'),
        meta: <MetaData>{
          pageTitle: 'Time',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Time',
              active: true,
            },
          ],
        },
      },
      {
        path: 'timeline',
        name: 'components-timeline',
        component: () => import('@/views/components/timeline/Timeline.vue'),
        meta: <MetaData>{
          pageTitle: 'Timeline',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Timeline',
              active: true,
            },
          ],
        },
      },
      {
        path: 'toasts',
        name: 'components-toasts',
        component: () => import('@/views/components/toasts/Toasts.vue'),
        meta: <MetaData>{
          pageTitle: 'Toasts',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Toasts',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tooltip',
        name: 'components-tooltip',
        component: () => import('@/views/components/tooltip/Tooltip.vue'),
        meta: <MetaData>{
          pageTitle: 'Tooltip',
          breadcrumb: [
            {
              text: 'Components',
            },
            {
              text: 'Tooltip',
              active: true,
            },
          ],
        },
      },
    ],
  },
]

export const _ = null
