import { RouteConfig } from 'vue-router'

import { apps } from './apps'
import { dashboards } from './dashboard'
import { forms } from './forms'
import { tables } from './table'
import { errors } from './error'
import { pages } from './pages'
import { extensions } from './extensions'
import { others } from './extensions/other'
import { uis } from './ui'
import { cards } from './card'
import { components } from './components'
import { chartsAndMaps } from './charts-and-maps'

export const routes: RouteConfig[] = [
  ...apps,
  ...dashboards,
  ...forms,
  ...tables,
  ...errors,
  ...pages,
  ...others,
  ...uis,
  ...cards,
  ...components,
  ...extensions,
  ...chartsAndMaps,
]

export const _ = null
