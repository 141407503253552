import Vue from 'vue'
import {
  Route,
  RouteConfig,
  MetaData,
  NavigationGuardNext,
} from 'vue-router'
import { createEmptyView } from '@/views/route-view'

export const apps: RouteConfig[] = [{
  path: '/apps',
  name: 'apps',
  redirect: '/apps/calendar',
  component: createEmptyView(),
  children: [
    {
      path: 'calendar',
      name: 'apps-calendar',
      component: () => import('./calendar/Calendar.vue'),
    },
    {
      path: 'empty',
      name: 'apps-empty',
      component: () => import('./empty/Empty.vue'),
      meta: <MetaData>{
        contentRenderer: 'sidebar-left',
      },
    },
    // *===============================================---*
    // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
    // *===============================================---*
    {
      path: 'email',
      name: 'apps-email',
      component: () => import('./email/Email.vue'),
      meta: <MetaData>{
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
      },
      children: [
        {
          path: ':folder',
          name: 'apps-email-folder',
          component: () => import('./email/Email.vue'),
          meta: <MetaData>{
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
          },
          beforeEnter(to: Route, _: Route, next: NavigationGuardNext<Vue>) {
            if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) {
              next()
            } else {
              next({ name: 'error-404' })
            }
          },
        },
        {
          path: 'label/:label',
          name: 'apps-email-label',
          component: () => import('./email/Email.vue'),
          meta: <MetaData>{
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'apps-email',
          },
          beforeEnter(to: Route, _: Route, next: NavigationGuardNext<Vue>) {
            if (['personal', 'company', 'important', 'private'].includes(to.params.label)) {
              next()
            } else {
              next({ name: 'error-404' })
            }
          },
        },
      ],
    },

    // *===============================================---*
    // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
    // *===============================================---*
    {
      path: 'todo',
      name: 'apps-todo',
      component: () => import('./todo/Todo.vue'),
      meta: <MetaData>{
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
      children: [
        {
          path: ':filter',
          name: 'apps-todo-filter',
          component: () => import('./todo/Todo.vue'),
          meta: <MetaData>{
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
          },
          beforeEnter(to: any, _: any, next: any) {
            if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
            else next({ name: 'error-404' })
          },
        },
        {
          path: 'tag/:tag',
          name: 'apps-todo-tag',
          component: () => import('./todo/Todo.vue'),
          meta: <MetaData>{
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
          },
          beforeEnter(to: any, _: any, next: any) {
            if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
            else next({ name: 'error-404' })
          },
        },
      ],
    },

    // *===============================================---*
    // *--------- CHAT  ---------------------------------------*
    // *===============================================---*
    {
      path: 'chat',
      name: 'apps-chat',
      component: () => import('./chat/Chat.vue'),
      meta: <MetaData>{
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
      },
    },

    // *===============================================---*
    // *--------- ECOMMERCE  ---------------------------------------*
    // *===============================================---*
    {
      path: 'e-commerce',
      name: 'e-commerce',
      redirect: 'e-commerce/shop',
      component: createEmptyView(),
      children: [
        {
          path: 'shop',
          name: 'apps-e-commerce-shop',
          component: () => import('./e-commerce/e-commerce-shop/ECommerceShop.vue'),
          meta: <MetaData>{
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
            pageTitle: 'Shop',
            breadcrumb: [
              {
                text: 'ECommerce',
              },
              {
                text: 'Shop',
                active: true,
              },
            ],
          },
        },
        {
          path: 'wishlist',
          name: 'apps-e-commerce-wishlist',
          component: () => import('./e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
          meta: <MetaData>{
            pageTitle: 'Wishlist',
            contentClass: 'ecommerce-application',
            breadcrumb: [
              {
                text: 'ECommerce',
              },
              {
                text: 'Wishlist',
                active: true,
              },
            ],
          },
        },
        {
          path: 'checkout',
          name: 'apps-e-commerce-checkout',
          component: () => import('./e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
          meta: <MetaData>{
            pageTitle: 'Checkout',
            contentClass: 'ecommerce-application',
            breadcrumb: [
              {
                text: 'ECommerce',
              },
              {
                text: 'Checkout',
                active: true,
              },
            ],
          },
        },
        {
          path: ':slug',
          name: 'apps-e-commerce-product-details',
          component: () => import('./e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
          meta: <MetaData>{
            pageTitle: 'Product Details',
            contentClass: 'ecommerce-application',
            breadcrumb: [
              {
                text: 'ECommerce',
              },
              {
                text: 'Shop',
                active: true,
              },
              {
                text: 'Product Details',
                active: true,
              },
            ],
          },
        },
      ],
    },

    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
      path: 'users',
      name: 'users',
      redirect: 'users/list',
      component: createEmptyView(),
      children: [
        {
          path: 'list',
          name: 'apps-users-list',
          component: () => import('./user/users-list/UsersList.vue'),
        },
        {
          path: 'view/:id',
          name: 'apps-users-view',
          component: () => import('./user/users-view/UsersView.vue'),
        },
        {
          path: 'edit/:id',
          name: 'apps-users-edit',
          component: () => import('./user/users-edit/UsersEdit.vue'),
        },
      ],
    },

    // Invoice
    {
      path: 'invoice',
      name: 'invoice',
      redirect: 'invoice/list',
      component: createEmptyView(),
      children: [
        {
          path: 'list',
          name: 'apps-invoice-list',
          component: () => import('./invoice/invoice-list/InvoiceList.vue'),
        },
        {
          path: 'preview/:id',
          name: 'apps-invoice-preview',
          component: () => import('./invoice/invoice-preview/InvoicePreview.vue'),
        },
        {
          path: 'add/',
          name: 'apps-invoice-add',
          component: () => import('./invoice/invoice-add/InvoiceAdd.vue'),
        },
        {
          path: 'edit/:id',
          name: 'apps-invoice-edit',
          component: () => import('./invoice/invoice-edit/InvoiceEdit.vue'),
        },
      ],
    },
  ],
}]

export const _ = null
