import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '@/views/route-view'

export const dashboards: RouteConfig[] = [{
  path: '/dashboard',
  name: 'dashboard',
  redirect: '/dashboard/analytics',
  component: createEmptyView(),
  children: [{
    path: 'analytics',
    name: 'dashboard-analytics',
    component: () => import('./analytics/Analytics.vue'),
  },
  {
    path: 'ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('./ecommerce/Ecommerce.vue'),
  }],
}]

export const _ = null
