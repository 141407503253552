import Vue from 'vue'
import Vuex from 'vuex'
import { getModule as getModuler, VuexModule } from 'vuex-module-decorators'

// Modules
import { ECOMMERCE_MODULE_NAME, ECommerceState, ECommerceModule } from '@/views/apps/e-commerce/eCommerceStoreModule'
import { APP_MODULE_NAME, AppState, AppModule } from '@/store/app'
import { APP_CONFIG_MODULE_NAME, AppConfigState, AppConfigModule } from '@/store/app-config'
import { VERTICAL_MENU_MODULE_NAME, VerticalMenuState, VerticalMenuModule } from '@/store/vertical-menu'

Vue.use(Vuex)

export interface RootState {
  [APP_MODULE_NAME]: AppState,
  [APP_CONFIG_MODULE_NAME]: AppConfigState,
  [VERTICAL_MENU_MODULE_NAME]: VerticalMenuState,
  [ECOMMERCE_MODULE_NAME]: ECommerceState,
}

export const store = new Vuex.Store<RootState>({
  modules: {
    [APP_MODULE_NAME]: AppModule,
    [APP_CONFIG_MODULE_NAME]: AppConfigModule,
    [VERTICAL_MENU_MODULE_NAME]: VerticalMenuModule,
    [ECOMMERCE_MODULE_NAME]: ECommerceModule,
  },
  strict: !!process.env.DEV,
})

export const getModule = <M extends VuexModule>(moduleClass: ConstructorOf<M>): M => getModuler(moduleClass, store)

export const getAppStore = () => getModule(AppModule)

export const getAppConfigStore = () => getModule(AppConfigModule)

export const getVerticalMenuStore = () => getModule(VerticalMenuModule)
