import Vue from 'vue'
import VueRouter, { Route, NavigationGuardNext, MetaData } from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import { routes } from '@/views'

Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard' },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    ...routes,
  ],
})

router.beforeEach((to: Route, _: Route, next: NavigationGuardNext<Vue>) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  if (to.meta) {
    const { redirectIfLoggedIn }: MetaData = to.meta

    // Redirect if logged in
    if (redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')

  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export const _ = null
