import { RouteConfig, MetaData } from 'vue-router'
import { createEmptyView } from '../route-view'

export const extensions: RouteConfig[] = [
  {
    path: '/extensions',
    name: 'extensions',
    redirect: '/extensions/sweet-alert',
    component: createEmptyView(),
    children: [
      {
        path: 'sweet-alert',
        name: 'extensions-sweet-alert',
        component: () => import('./sweet-alert/SweetAlert.vue'),
        meta: <MetaData>{
          pageTitle: 'Sweet Alerts',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Sweet Alerts',
              active: true,
            },
          ],
        },
      },

      {
        path: 'drag-drop',
        name: 'extensions-drag-and-drop',
        component: () => import('./drag-drop/DragDrop.vue'),
        meta: <MetaData>{
          pageTitle: 'Drag & Drop',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Drag & Drop',
              active: true,
            },
          ],
        },
      },
      {
        path: 'swiper',
        name: 'extensions-swiper',
        component: () => import('./swiper/Swiper.vue'),
        meta: <MetaData>{
          pageTitle: 'Swiper',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Swiper',
              active: true,
            },
          ],
        },
      },
      {
        path: 'clipboard',
        name: 'extensions-clipboard',
        component: () => import('./clipboard/Clipboard.vue'),
        meta: <MetaData>{
          pageTitle: 'Clipboard',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Clipboard',
              active: true,
            },
          ],
        },
      },
      {
        path: 'context-menu',
        name: 'extensions-context-menu',
        component: () => import('./context-menu/ContextMenu.vue'),
        meta: <MetaData>{
          pageTitle: 'Context Menu',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Context Menu',
              active: true,
            },
          ],
        },
      },
      {
        path: 'toastification',
        name: 'extensions-toastification',
        component: () => import('./toastification/Toastification.vue'),
        meta: <MetaData>{
          pageTitle: 'Toastification',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Toastification',
              active: true,
            },
          ],
        },
      },
      {
        path: 'i18n',
        name: 'extensions-i18n',
        component: () => import('./i18n/i18n.vue'),
        meta: <MetaData>{
          pageTitle: 'I18n',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'I18n',
              active: true,
            },
          ],
        },
      },
      {
        path: 'slider',
        name: 'extensions-slider',
        component: () => import('./slider/Slider.vue'),
        meta: <MetaData>{
          pageTitle: 'Slider',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Slider',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tour',
        name: 'extensions-tour',
        component: () => import('./tour/Tour.vue'),
        meta: <MetaData>{
          pageTitle: 'Tour',
          breadcrumb: [
            {
              text: 'Extensions',
            },
            {
              text: 'Tour',
              active: true,
            },
          ],
        },
      },
      // {
      //   path: 'tree',
      //   name: 'extensions-tree',
      //   component: () => import('./tree/Tree.vue'),
      //   meta: <MetaData>{
      //     pageTitle: 'Tree',
      //     breadcrumb: [
      //       {
      //         text: 'Extensions',
      //       },
      //       {
      //         text: 'Tree',
      //         active: true,
      //       },
      //     ],
      //   },
      // },
    ],
  },
]

export const _ = null
